
import {PropType, defineComponent} from 'vue';
import SocialLink from '@/models/SocialLink';

export default defineComponent({
    props: {
        links: {
            required: true,
            type: Array as PropType<SocialLink[]>,
        },
    },
});
