
import {PropType, computed, defineComponent} from 'vue';
import PortfolioEntry from '@/models/PortfolioEntry';

export default defineComponent({
    props: {
        entry: {
            required: true,
            type: Object as PropType<PortfolioEntry>,
        },
    },
    setup(props) {
        const prefixHeaderColor = computed(() => {
            switch (props.entry.prefixHeader) {
                case 'vue':
                    return '#41B883';
                case 'javascript':
                    return '#EFD81D';
                case 'golang':
                    return '#007D9C';
                case 'typescript':
                    return '#2F74C0';
                default:
                    return '#fff';
            }
        });

        const subheaderText = computed(() => {
            let text = '';

            if (props.entry.location) text += props.entry.location;

            if (props.entry.location && props.entry.startDate) text += ' - ';

            if (props.entry.startDate) text += props.entry.startDate;

            if (props.entry.endDate) text += ` / ${props.entry.endDate}`;

            return text;
        });

        const emoji = computed(() => {
            switch (props.entry.type) {
                case 'project':
                    return '💻';
                case 'study':
                    return '🎓';
                case 'work':
                    return '💼';
                case 'experience':
                    return '🌈';
                default:
                    return null;
            }
        });

        const hasLink = computed(() => (props.entry.url && props.entry.url.length));

        const openLink = (): void => {
            if (hasLink.value) {
                window.open(props.entry.url, '_blank');
            }
        };

        return {
            emoji,
            hasLink,
            prefixHeaderColor,
            subheaderText,
            openLink,
        };
    },
});
