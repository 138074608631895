
import {PropType, defineComponent, ref} from 'vue';
import PortfolioEntry from '@/models/PortfolioEntry';
import PortfolioItem from '@/components/common/PortfolioItem.vue';

export default defineComponent({
    components: {
        PortfolioItem,
    },
    props: {
        entries: {
            required: true,
            type: Array as PropType<PortfolioEntry[]>,
        },
    },
    setup() {
        const hoveredItemIndex = ref<number | undefined>(undefined);

        return {
            hoveredItemIndex,
        };
    },
});
